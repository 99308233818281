<script>
import { advertisementService } from "../../../helpers/backend/advertisement.service"
export default {
  data() {
    return {
      form: {
        id: null,
        name: "",
        media: null,
        advertisement_type_id: null,
        is_video: false,
      },
      busy: false,
      media_url: "",
      advertisement_type_options: [{ text: "Select One", value: null }],
      show: true,
      showDismissibleAlert: false,
      error: "",
      active: false,
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    isUpdateState() {
      return this.form.id != null
    },
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      //this.form.media = this.$refs.file.files[0]
      this.busy = true
      advertisementService.CreateOrUpdateAdvertisement(this.form).then(
        (data) => {
          if (data != null) {
            this.reset()
            this.$emit("refresh")
            this.showDismissibleAlert = true
            this.error = ""
            this.setActive(false)
          }
          this.busy = false
        },
        (error) => {
          this.showDismissibleAlert = true
          this.error =
            "Failed to Insert/Update Advertisement. Error : " + error
          this.busy = false
        }
      )
    },
    reset() {
      // Reset our form values
      this.form.id = null
      this.form.name = ""
      this.form.media = null
      this.media_url = ""
      this.form.advertisement_type_id = null
      this.form.is_video = false,

      // Trick to reset/clear native browser form validation state
      this.show = false
      this.showDismissibleAlert = false
      this.error = ""
      this.$nextTick(() => {
        this.show = true
      })
    },
    onReset(event) {
      event.preventDefault()
      this.setActive(false)
      this.reset()
    },
    setActive(b, outside_triger) {
      this.active = b
      if (outside_triger) return
      this.$emit("active_change", b)
    },
    initUpdate(adId) {
      this.setActive(true)

      this.$nextTick(() => {
        this.reset()
        this.$refs.name.$el.focus()
        this.busy = true
        advertisementService.getAdvertisementById(adId).then(
          (data) => {
            if (data.advertisement != null) {
              this.form.id = data.advertisement.id
              this.form.name = data.advertisement.name
              this.media_url = data.advertisement.media_url
              this.form.advertisement_type_id =
                data.advertisement.advertisement_type_id
              this.form.is_video = data.advertisement.is_video
            } else {
              this.showDismissibleAlert = true
              this.error =
                "Failed to get advertisement to update. Error : Advertisement not found"
            }
            this.busy = false
          },
          (err) => {
            this.showDismissibleAlert = true
            this.error =
              "Failed to get advertisement to update. Error : " + err
            this.busy = false
          }
        )
      })
    },
    invokeCreate() {
      this.setActive(true)
    },
    fetchData() {
      advertisementService.getAllAdvertisementType().then(
        (data) => {
          this.advertisement_type_options =
            this.advertisement_type_options.concat(
              data.advertisement_types.map(function (mType) {
                return { text: mType.name, value: mType.id }
              })
            )
          //alert(typeof(this.machine_types))
        },
        (err) => {
          this.showDismissibleAlert = true
          this.error = "Failed to get advertisement type. Error : " + err
        }
      )
    },
  },
};
</script>


<template>
  <div v-if="this.active">
    <b-alert
      :variant="error == '' ? 'success' : 'danger'"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
    >
      <div v-if="error != ''">
        {{ error }}
      </div>
      <div v-else>Success perform Insert/Update action</div>
    </b-alert>
    <div class="card">
      <div class="card-body">
        <div>
          <b-form @submit="onSubmit" @reset="onReset" v-if="show" ref="form">
            <b-overlay :show="busy" rounded="lg" opacity="0.6">
              <b-form-group
                id="input-group-1"
                label="Advertisement Name:"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="form.name"
                  type="text"
                  placeholder="Enter Advertisement Name"
                  required
                  ref="name"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-2"
                label="Advertisement Media:"
                label-for="input-2"
              >
                <b-link
                  v-bind:href="this.media_url"
                  target="_blank"
                  v-show="this.media_url"
                  >Click here to see current media</b-link
                >
                <b-form-file
                  accept="image/*, video/*"
                  v-model="form.media"
                  id="input-2"
                  ref="file"
                  v-bind:required="this.form.id == null ? true : false"
                ></b-form-file>
              </b-form-group>

              <b-form-group
                id="input-group-3"
                label="Advertisement Type:"
                label-for="input-3"
              >
                <b-form-select
                  id="input-3"
                  v-model="form.advertisement_type_id"
                  :options="advertisement_type_options"
                  required
                ></b-form-select>
              </b-form-group>

              <b-form-group
                id="input-group-4"
                label="Is Video Content:"
                label-for="input-4"
              >
                <b-form-checkbox
                  id="input-4"
                  v-model="form.is_video"
                  switch
                  size="lg"
                ></b-form-checkbox>
              </b-form-group>

              <b-button class="mr-2" type="submit" variant="primary">
                {{ isUpdateState ? "Update" : "Submit" }}
              </b-button>
              <b-button type="reset" variant="danger">Cancel</b-button>
            </b-overlay>
          </b-form>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <b-form-group>
      <b-button variant="primary" @click="invokeCreate">Create new</b-button>
    </b-form-group>
  </div>
</template>



